exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-before-we-begin-tsx": () => import("./../../../src/pages/before-we-begin.tsx" /* webpackChunkName: "component---src-pages-before-we-begin-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-first-upload-tsx": () => import("./../../../src/pages/first-upload.tsx" /* webpackChunkName: "component---src-pages-first-upload-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-tax-application-tsx": () => import("./../../../src/pages/tax-application.tsx" /* webpackChunkName: "component---src-pages-tax-application-tsx" */)
}

